.cfra-ag-grid {
    .ag-header-cell-text {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: normal;
        word-wrap: break-word;
    }

    .ag-right-aligned-header .ag-header-cell-text {
        text-align: right;
    }
}
